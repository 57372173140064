<template>
    <div class="single-language-container" @click="actSelectLanguage()">
        <div class="single-language-check" v-if="isTabSelected">
            <!-- <img src="@/assets/icons/tick-selected.svg" alt /> -->
            <langticksvg></langticksvg>
        </div>

        <div class="single-language-select" v-else>
            <img src="@/assets/icons/tick-not-selected.svg" alt />
        </div>
        <div  v-bind:class="['single-language', isTabSelected ? 'active' : 'inactive' ]">{{selectedLanguage.title}}</div>
        <div v-bind:class="['single-language-symbol', isTabSelected ? 'single-language-symbol-active' : 'single-language-symbol-inactive']">
            <img :src="require(`@/assets/icons/${selectedLanguage.icon}`)" alt />
        </div>
    </div>
</template>

<script>
export default {
    data () {
      return {
        isTabSelected: false,
        selectedLanguage: ''
        
      }
    },
    watch: {
      language: {
            handler: function(newValue) {
                console.log(newValue)
                this.isTabSelected  = newValue.isActive;
                this.selectedLanguage = newValue;
            },
            deep: true
        }
    },
    props:['language'],
    methods: {
        actSelectLanguage () {
          if (this.isTabSelected) return;
            this.isTabSelected = !this.isTabSelected

          
            this.$emit("callbackLang", {"key": this.language.key, "isActive": this.isTabSelected})
        }
    },
    mounted() {
      this.isTabSelected = this.language.isActive;
      this.selectedLanguage = this.language;
    },

    components: {
    langticksvg: () => import(/* webpackChunkName: "langticksvg" */ "../SvgImages/Ticksvg.vue")
  },
}
</script>

<style lang="scss" scoped>
    @import "@/sass/_variables.scss";
    @import "./SingleLangauge.scss"
</style>
